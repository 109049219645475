<template>
	<div card class="p-5 bg-white mr-7">
		<div class="d-flex justify-content-between align-items-center mb-7">
			<h3 class="font-weight-bold text-dark mb-0">
				Sites for <span class="font-italic">{{ adminAccount.username }}</span>
			</h3>
			<div class="d-flex justify-content-end">
				<b-button @click="loadAllSites()" variant="primary" v-if="!showSiteList && !showAccountsList" :disabled="showUnmonitoredForm">
					Add Site to Account
				</b-button>
				<b-button v-else @click="hideSiteList()" variant="danger">
					Hide Site List
				</b-button>
				<b-button v-if="!showSiteList && !showAccountsList" @click="toggleUnmonitoredForm" variant="outline-dark" class="ml-2">
					<span v-if="showUnmonitoredForm">Cancel</span>
					<span v-else>Add Unmonitored Site</span>
				</b-button>
			</div>
		</div>
		<!-- Begin: Site List -->
		<transition name="fade-in-up">
			<div v-if="showSiteList || showAccountsList" class="card card-custom card-stretch gutter-b mt-6 site-list">
				<div class="card-header border-0 pt-3 d-flex align-items-center" style="min-height: 40px">
					<div class="d-flex align-items-center justify-content-between w-100">
						<h3 v-if="showSiteList">Select Site to Add:</h3>
						<h3 v-else>Select Account to Add:</h3>
						<div class="search-box">
							<b-form-input class="search-bar" size="sm" v-model="searchText" placeholder="Search" debounce="500" />
							<b-icon-search class="search-icon" />
						</div>
						<div>
							<b-button variant="primary" @click="loadAllAccounts()" v-if="showSiteList">Add by User Account</b-button>
							<b-button variant="primary" @click="loadAllSites()" v-else>Add by Site Name</b-button>
						</div>
					</div>
				</div>
				<div class="card-body pt-2 pb-0" style="height: 400px; overflow-y: scroll">
					<ITCSpinner :loaded="loaded">
						<b-table
							:items="showSiteList ? filteredSites : filteredAccounts"
							:fields="showSiteList ? ['Name'] : ['name', 'username', 'sitecount']"
							:current-page="currentPage"
							thead-class="hidden-header"
							:per-page="perPage"
						>
							<template v-if="showSiteList" #cell(Name)="data">
								<span
									class="svg-icon svg-icon-sm"
									@click="addSiteToAccount(data.item.SiteId)"
									v-b-tooltip="'Add Site to Account'"
									style="cursor: pointer"
								>
									<inline-svg src="/media/svg/icons/Code/Plus.svg"></inline-svg>
								</span>
								{{ data.value }}
							</template>
							<template v-else #cell(name)="data">
								<span
									class="svg-icon svg-icon-sm"
									@click="addAccountSites(data.item.AccountID)"
									v-b-tooltip="'Add Account\'s Sites to List'"
									style="cursor: pointer"
								>
									<inline-svg src="/media/svg/icons/Code/Plus.svg"></inline-svg>
								</span>
								{{ data.value }}
							</template>
						</b-table>
					</ITCSpinner>
				</div>
				<div class="footer" v-if="showSiteList && filteredSites.length > 0 && allSites.length > perPage">
					<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredSites.length" :per-page="perPage" />
				</div>
				<div class="footer" v-else-if="filteredAccounts.length > 0 && accounts.length > perPage">
					<b-pagination class="pagination" v-model="currentPage" :total-rows="filteredAccounts.length" :per-page="perPage" />
				</div>
			</div>
		</transition>
		<b-modal id="add-account" @ok="submitSites" @cancel="closeModal">
			<template #modal-title> Add Sites from {{ addByAccount.name }} </template>
			<ITCSpinner :loaded="!accountSitesLoading">
				<h5>This will add {{ addByAccount.sites.length }} new sites to {{ adminAccount.name }}'s account. Proceed?</h5>
				<div v-for="site in addByAccount.sites" :key="site.id" class="ml-2 text-dark-75">{{ site.name }}</div>
			</ITCSpinner>
		</b-modal>
		<!-- END: Site List -->

		<!-- BEGIN: Unmonitored Form -->
		<transition name="fade-in-up">
			<div v-show="showUnmonitoredForm" class="my-6">
				<b-card class="col-md-6 offset-md-3">
					<h4><span v-if="!editingUnmonitored">Add</span><span v-if="editingUnmonitored">Edit</span> Unmonitored Site</h4>
					<p class="text-dark-50">Add a static site to a user's account which will be visible on dashboard map</p>
					<b-form @submit.prevent="submitUnmonitoredSite">
						<b-form-group label-for="unmonitored-name" label="Site Name">
							<b-form-input id="unmonitored-name" v-model="unmonitored.site_name" required></b-form-input>
						</b-form-group>
						<b-form-group label-for="unmonitored-lat" label="Latitude">
							<b-form-input id="unmonitored-lat" v-model="unmonitored.lat" required></b-form-input>
						</b-form-group>
						<b-form-group label-for="unmonitored-lon" label="Longitude">
							<b-form-input id="unmonitored-lon" v-model="unmonitored.lon" required></b-form-input>
						</b-form-group>
						<div class="text-center">
							<b-button type="submit" variant="primary" class="mr-2" :disabled="submittedSite">
								<div v-if="submittedSite"><b-spinner small type="grow" class="" /> Submitting</div>
								<span v-else>Submit</span>
							</b-button>
							<b-button type="button" @click="cancelUnmonitoredSite" variant="danger">Cancel</b-button>
						</div>
					</b-form>
				</b-card>
			</div>
		</transition>
		<!-- END: Unmonitored Form -->
		<div card-body>
			<div
				v-for="(site, index) in adminAccount.sites"
				:key="site + index"
				class="border-bottom"
				style="display: flex; justify-content-center: space-between"
			>
				<div class="mb-3 mt-3">
					<span @click="removeSite(site.id)" class="svg-icon svg-icon-sm svg-icon-danger" v-b-tooltip="'Remove site from account'">
						<inline-svg src="/media/svg/icons/Navigation/Close.svg" />
					</span>
				</div>
				<div class="mb-3 mt-3 font-weight-bold text-dark">{{ site.name }} | {{ site.types }}</div>
				<div class="mb-3 mt-3 font-weight-bold text-dark">
					<router-link :to="{ name: 'admineditsite', params: { id: site.id } }">
						<span class="svg-icon svg-icon-sm svg-icon-primary" v-b-tooltip="'Edit site'">
							<inline-svg src="/media/svg/icons/Design/Edit.svg" />
						</span>
					</router-link>
				</div>
			</div>
			<div
				v-for="site in adminAccount.unmonitored_sites"
				:key="'u' + site.id"
				class="border-bottom"
				style="display: flex; justify-content-center: space-between"
			>
				<div class="mb-3 mt-3">
					<span
						@click="deleteUnmonitoredSite(site.id, site.site_name)"
						class="svg-icon svg-icon-sm svg-icon-danger pointer"
						v-b-tooltip="'Remove site from account'"
					>
						<inline-svg src="/media/svg/icons/Navigation/Close.svg" />
					</span>
				</div>
				<div class="mb-3 mt-3 font-weight-bold text-dark">{{ site.site_name }} | Unmonitored</div>
				<div class="mb-3 mt-3 font-weight-bold text-dark">
					<span class="svg-icon svg-icon-sm svg-icon-primary pointer" v-b-tooltip="'Edit site'" @click="editUnmonitoredSite(site)">
						<inline-svg src="/media/svg/icons/Design/Edit.svg" />
					</span>
				</div>
			</div>

			<!--<button type="button" class="btn btn-secondary font-weight-bolder text-uppercase px-9 py-4">
					Duplicate Layout of Site
				</button>-->
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_ADMIN_EDIT_ACCOUNT } from '@/core/services/store/admin.module';

export default {
	components: {
		ITCSpinner: () => import('@/view/content/loaders/itcSpinner.vue'),
	},
	data() {
		return {
			loaded: false,
			allSites: [],
			accounts: [],
			showSiteList: false,
			showAccountsList: false,
			accountSitesLoading: true,
			showUnmonitoredForm: false,
			submittedSite: false,
			unmonitored: {
				site_name: null,
				lat: null,
				lon: null,
			},
			editingUnmonitored: false,
			searchText: '',
			currentPage: 1,
			perPage: 10,
			addByAccount: {
				id: null,
				name: null,
				sites: [],
			},
		};
	},
	computed: {
		...mapGetters(['adminAccount']),
		filteredSites() {
			if (!this.searchText) return this.allSites.filter(site => !this.siteIds.includes(site.SiteId));
			return this.allSites.filter(site => site.Name.toLowerCase().includes(this.searchText.toLowerCase()) && !this.siteIds.includes(site.SiteId));
		},
		filteredAccounts() {
			if (!this.searchText) return this.accounts.filter(account => this.$route.params.id !== account.AccountID);
			return this.accounts.filter(
				account =>
					account.name.toLowerCase().includes(this.searchText.toLowerCase()) || account.username.toLowerCase().includes(this.searchText.toLowerCase())
			);
		},
		siteIds() {
			// use for filtering sites already on the account from the add site list
			if (this.adminAccount.data && this.adminAccount.data.sites && this.adminAccount.data.sites.length > 0) {
				return this.adminAccount.data.sites.map(s => s.id);
			} else {
				return [];
			}
		},
	},
	methods: {
		removeSite(id) {
			this.$http.post('removesite', { id: id, AccountId: this.adminAccount.data.AccountID }).then(() => {
				this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
			});
		},
		addSiteToAccount(id) {
			this.$http.get(`addsiteaccount/${this.adminAccount.data.AccountID}/${id}`).then(() => {
				this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
			});
		},
		loadAllSites() {
			this.showAccountsList = false;
			this.showSiteList = true;
			if (this.allSites.length == 0) {
				this.$http.get('allsites').then(res => {
					this.allSites = res.data.data;
					this.loaded = true;
				});
			}
		},
		hideSiteList() {
			this.showSiteList = false;
			this.showAccountsList = false;
		},
		loadAllAccounts() {
			this.showSiteList = false;
			this.showAccountsList = true;
			if (this.accounts.length == 0) {
				this.$http.get('accounts').then(({ data }) => {
					this.accounts = data.data;
				});
			}
		},
		addAccountSites(AccountID) {
			this.accountSitesLoading = true;
			this.$bvModal.show('add-account');
			this.$http.get(`account/${AccountID}`).then(({ data }) => {
				this.accountSitesLoading = false;
				let siteIds = this.adminAccount.sites.map(site => site.id);
				let newSites = data.data.sites.filter(site => !siteIds.includes(site.id));
				this.addByAccount = {
					id: data.data.AccountID,
					name: data.data.name,
					sites: newSites,
				};
			});
		},
		submitSites(evt) {
			evt.preventDefault();
			this.accountSitesLoading = true;
			let sites = this.addByAccount.sites.map(site => site.id);
			this.$http.post(`addsiteaccount/${this.adminAccount.data.AccountID}`, sites).then(({ data }) => {
				if (data.status == 200) {
					this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
					this.accountSitesLoading = false;
					this.$bvModal.hide('add-account');
				}
			});
		},
		closeModal() {
			this.addByAccount = {
				id: null,
				name: null,
				sites: [],
			};
		},
		toggleUnmonitoredForm() {
			this.showUnmonitoredForm ? this.cancelUnmonitoredSite() : (this.showUnmonitoredForm = true);
			this.editingUnmonitored = false;
		},
		submitUnmonitoredSite() {
			this.submittedSite = true;
			if (this.editingUnmonitored) {
				this.$http.put(`accountsite/${this.adminAccount.AccountID}/unmonitored`, this.unmonitored).then(resp => {
					if (resp.data.status == 201) {
						this.submittedSite = false;
						this.cancelUnmonitoredSite();
						this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
					}
				});
			} else {
				this.$http.post(`accountsite/${this.adminAccount.AccountID}/unmonitored`, this.unmonitored).then(resp => {
					console.log(resp.data);
					if (resp.data.status == 201) {
						this.submittedSite = false;
						this.cancelUnmonitoredSite();
						this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
					}
				});
			}
		},
		cancelUnmonitoredSite() {
			this.unmonitored = {
				site_name: null,
				lat: null,
				lon: null,
			};
			this.showUnmonitoredForm = false;
		},
		deleteUnmonitoredSite(id, name) {
			this.$bvModal
				.msgBoxConfirm('Do you want to delete ' + name + '?', {
					title: 'Confirm Deletion',
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: 'YES',
					cancelTitle: 'NO',
					footerClass: 'p-2',
					hideHeaderClose: false,
				})
				.then(value => {
					if (value) {
						this.$http.delete(`accountsite/unmonitored/${id}`).then(resp => {
							if (resp.data.status == 204) {
								this.$store.dispatch(SET_ADMIN_EDIT_ACCOUNT, this.$route.params.id);
							}
						});
					}
				})
				.catch(err => {
					// An error occurred
				});
		},
		editUnmonitoredSite({ site_name, lat, lon }) {
			this.unmonitored = { site_name, lat, lon };
			this.showUnmonitoredForm = true;
			this.editingUnmonitored = true;
		},
	},
};
</script>

<style scoped>
.search-box {
	position: relative;
	width: 300px;
}
.search-bar {
	padding: 17px 30px;
}
.search-icon {
	position: absolute;
	top: 10px;
	left: 10px;
}
.footer {
	padding: 15px 30px 0px;
}
.pagination {
	float: right;
}
.hidden-header {
	display: none;
}
.site-list {
	border: 1px solid #c4c4c4;
}
</style>
